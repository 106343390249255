import { slideImageOptions, composePrismicImageUrl } from './utils';
export var transformShowroomItems = function (showroomItems) {
    var x1 = slideImageOptions.x1, x2 = slideImageOptions.x2, x3 = slideImageOptions.x3, thumbnail = slideImageOptions.thumbnail;
    return showroomItems.map(function (_a) {
        var image = _a.image, name = _a.name, link = _a.link.fullUrl;
        return ({
            description: name,
            link: link,
            image: {
                x1: composePrismicImageUrl(image.original.url, x1),
                x2: composePrismicImageUrl(image.original.url, x2),
                x3: composePrismicImageUrl(image.original.url, x3),
                thumbnail: composePrismicImageUrl(image.original.url, thumbnail),
                alt: name,
            },
        });
    });
};
