var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var createOptions = function (w, h) { return ({ w: w, h: h, fit: 'crop' }); };
export var slideImageOptions = {
    thumbnail: createOptions(75, 51),
    x1: createOptions(250, 170),
    x2: createOptions(500, 340),
    x3: createOptions(750, 510),
};
export var composePrismicImageUrl = function (imageUrl, options) {
    var url = new URL(imageUrl);
    if (url.searchParams.has('rect')) {
        url.searchParams.delete('rect');
    }
    Object.entries(options).forEach(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        if (url.searchParams.has(key)) {
            url.searchParams.set(key, value);
        }
        else {
            url.searchParams.append(key, value);
        }
    });
    return url.href;
};
