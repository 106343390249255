var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from 'react';
import { VisualNavigationType } from 'corgi-types';
import { useGQLLocale } from '../locale';
import { loadShowroomItems, getShowroomLoaderParams, } from './loaders/showrooms';
export var useLoadVisualNavigation = function (_a) {
    var _b = _a.items, initialItems = _b === void 0 ? [] : _b, type = _a.type, restProps = __rest(_a, ["items", "type"]);
    var locale = useGQLLocale();
    var _c = __read(useState(type === VisualNavigationType.Default), 2), loaded = _c[0], setLoaded = _c[1];
    var _d = __read(useState(initialItems), 2), items = _d[0], setItems = _d[1];
    var _e = __read(useState(null), 2), error = _e[0], setError = _e[1];
    useEffect(function () {
        if (type === VisualNavigationType.Showroom) {
            var commonParams = {
                locale: locale,
                setLoaded: setLoaded,
                setItems: setItems,
                setError: setError,
            };
            var loadVisualNavigationItems = loadShowroomItems(__assign(__assign({}, getShowroomLoaderParams(restProps)), commonParams));
            loadVisualNavigationItems();
        }
    }, [type]);
    return { items: items, loaded: loaded, error: error };
};
