var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { width, color, height, space } from 'styled-system';
var RectShape = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), width, height, space, color);
RectShape.defaultProps = {
    className: 'rect-shape',
    width: 1,
    bg: 'sys.neutral.background.hover',
};
export default RectShape;
var templateObject_1;
